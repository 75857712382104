import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Slider from "../Slider/SliderServices";

const Testimonial = ({ className, headingLevel }) => {
  const data = useStaticQuery(graphql`
    {
      assistedLiving: file(
        relativePath: {
          eq: "0.0 Repeated Modules/Services Slider/Assisted Living.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 688)
        }
      }
      alzheimersCare: file(
        relativePath: {
          eq: "0.0 Repeated Modules/Services Slider/Alzheimer_s Care.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 688)
        }
      }
      dementiaCare: file(
        relativePath: {
          eq: "0.0 Repeated Modules/Services Slider/dementia-care.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 688)
        }
      }
      enrichingExperience: file(
        relativePath: {
          eq: "0.0 Repeated Modules/Services Slider/Enriching Experiences.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 688)
        }
      }
      hospiceCare: file(
        relativePath: {
          eq: "0.0 Repeated Modules/Services Slider/Assisted Living.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 688)
        }
      }
      respiteCare: file(
        relativePath: {
          eq: "0.0 Repeated Modules/Services Slider/respite-care.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 688)
        }
      }
    }
  `);

  const slides = [
    {
      image: data.assistedLiving.childImageSharp.gatsbyImageData,
      heading: "Assisted Living",
      text: "HarborView assisted living residents receive help and support tailored to their needs.",
      //link: "/services",
      link: "/san-diego-assisted-living/",
    },
    {
      image: data.alzheimersCare.childImageSharp.gatsbyImageData,
      heading: "Alzheimer’s Care",
      text: "Residents with Alzheimer’s disease receive personalized memory care that includes therapeutic activity programs.",
      //link: "/services",
      link: "/san-diego-alzheimers-care/",
    },
    {
      image: data.dementiaCare.childImageSharp.gatsbyImageData,
      heading: "Dementia Care",
      text: "Our staff has specialized training in all forms of dementia and offers 24/7 care and supervision.",
      //link: "/services",
      link: "/dementia-care-san-diego/",
    },
    {
      image: data.enrichingExperience.childImageSharp.gatsbyImageData,
      heading: "Hospice Accommodations",
      text: "We have a hospice waiver that allows a terminally ill resident to stay with us, and we accommodate hospice services.",
      //link: "/services",
      link: "/san-diego-hospice-care/",
    },
    {
      image: data.hospiceCare.childImageSharp.gatsbyImageData,
      heading: "Respite Care",
      text: "Caregivers need care too, and that’s why we offer short-term relief that allows them to recharge.",
      //link: "/services",
      link: "/san-diego-respite-care/",
    },
    {
      image: data.respiteCare.childImageSharp.gatsbyImageData,
      heading: "Enriching Experiences",
      text: "Life is meant to be lived, and we’re committed to ensuring our residents continue to live life to the fullest.",
      //link: "/services",
      link: "/senior-living-san-diego/",
    },
  ];

  return (
    <section className={`overflow-x-hidden pb-20 md:pb-32 ${className}`}>
      <div className="container">
        <header className="mb-10 max-w-3xl md:mb-14">
          <h2>Senior Assisted Living and Care</h2>
          <p>
            At HarborView, residents receive compassionate, individualized care
            focusing on their physical well-being and the social and spiritual
            facets of their lives. Our services include:
          </p>
        </header>

        <Slider slides={slides} />
      </div>
    </section>
  );
};

export default Testimonial;
