import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
// import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import RecentPosts from "../components/Repeating/RecentPosts";
import HarborViewLifestyle from "../components/Repeating/HarborViewLifestyle";
import Services from "../components/Repeating/Services";
import CallToAction from "../components/Repeating/CTA";
// import ButtonGhost from "../components/Button/ButtonGhost";
import ButtonGhostMobile from "../components/Button/ButtonGhostMobile";
import ButtonSolid from "../components/Button/ButtonSolid";

const IndexPage = ({ data }) => {
  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="HarborView Senior Assisted Living | San Diego, CA"
        description="HarborView Senior Assisted Living is a memory care community in San Diego that's dedicated to providing gentle, individualized care. Call to schedule a tour."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      {/* START OF HERO */}
      <section className="relative bg-primary-500 md:h-[350px] md:bg-transparent lg:h-[450px] xl:h-[650px] ">
        {/* START OF DESKTOP UNDERLAY */}
        <div className=" absolute -bottom-[70px] left-0 mt-[50px] hidden w-full md:mt-[60px] md:block  lg:bottom-0">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-desktop.png"
            loading="eager"
            placeholder="none"
            objectPosition="bottom"
          />
        </div>
        {/* END OF DESKTOP UNDERLAY */}
        {/* START OF HERO CONTENT */}
        <div className="relative flex h-full flex-col justify-center pt-12 md:pt-[inherit] ">
          <div>
            {/* START OF DESKTOP */}
            <div className="hidden lg:block">
              <div className="container">
                <p className="font-heading text-[54px] font-semibold leading-[60px] text-white md:text-[64px] md:leading-[70px] ">
                  Where they’ll feel<br></br>right at home.
                </p>
                <p className="max-w-[550px] max-w-[640px] font-body text-[22px] font-normal leading-[32px] text-white ">
                  Compassionate memory care and assisted living in San Diego’s
                  historic Bankers Hill.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Contact Us"
                  className="mx-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
                />
              </div>
            </div>
            {/* END OF DESKTOP */}
            {/* START OF TABLET */}
            <div className="hidden  md:block lg:hidden">
              <div className="container">
                <p className="font-heading text-[54px] font-semibold leading-[60px] text-white md:text-[64px] md:leading-[70px] ">
                  Where they’ll feel<br></br>right at home.
                </p>
                <p className="max-w-[425px] text-white">
                  Compassionate memory care and assisted living in San Diego’s
                  historic Bankers Hill.
                </p>
                <ButtonSolid modal="modal-contact" text="Contact Us" />
              </div>
            </div>
            {/* END OF TABLET */}
            {/* START OF MOBILE */}
            <div className="block  md:hidden">
              <div className="container py-4 text-center">
                <p className="font-heading text-[40px] leading-[50px] text-white">
                  Where they’ll feel<br></br>right at home.
                </p>
                <p className="text-[20px] font-normal leading-[34px] text-white">
                  Compassionate memory care and assisted living in San Diego’s
                  historic Bankers Hill.
                </p>
                <ButtonGhostMobile
                  modal="modal-contact"
                  text="Contact Us"
                  altStyle={2}
                />
              </div>
              <div className="block">
                <StaticImage
                  src="../images/1.0 Homepage/1.0 hero-mobile.jpg"
                  loading="eager"
                  placeholder="none"
                  className="h-full w-full"
                  imgClassName="object-top"
                />
              </div>
            </div>
            {/* END OF MOBILE */}
          </div>
        </div>
        {/* END OF HERO CONTENT  */}
      </section>
      {/* END OF HERO */}

      {/* START OF PAGE CONTENT */}
      <section className="mb-20 pt-[45px] md:mb-32 md:pt-[100px] lg:pt-24 xl:pt-0">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <h1 className="text-5xl ">
                HarborView Senior Assisted Living and Memory Care in San Diego
              </h1>
              <p>
                HarborView is a boutique assisted living home located in a
                charming, beautifully renovated Italian renaissance chateau in
                San Diego’s Bankers Hill neighborhood, overlooking the bay.
              </p>
              <p>
                Our well-trained and caring staff is dedicated to providing the
                best care and support for seniors living with{" "}
                <Link to="https://harborviewsandiego.com/san-diego-alzheimers-care/">
                  Alzheimer’s
                </Link>{" "}
                or related{" "}
                <Link to="https://harborviewsandiego.com/dementia-care-san-diego/">
                  dementia
                </Link>
                . Plus, residents enjoy a home-like setting where they can age
                in place gracefully and with dignity.
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/1.0 Homepage/2.0 intro.jpg"
                alt="Top Alzheimer’s & Dementia Care Serving Encinitas & North County, CA"
                width={560}
                loading="eager"
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </section>

      <Services />
      <HarborViewLifestyle
        className="mb-14  bg-secondary-400 p-4 md:mb-24 lg:p-24 "
        hide={4}
      />
      <WhyUs />
      {/* <Testimonials /> */}
      <RecentPosts />
      <CallToAction phonenumber={data.contactInfo.phonenumber} />
      {/* END OF PAGE CONTENT */}
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    sanitySiteSettings {
      title
    }

    allSanityServices {
      nodes {
        title
        _id
      }
      totalCount
    }
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }
  }
`;

export default IndexPage;
